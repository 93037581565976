import { default as activitieswHDF3xSI2CMeta } from "/app/pages/activities.vue?macro=true";
import { default as _91id_935onrHzdxxbMeta } from "/app/pages/betting-shop/[id].vue?macro=true";
import { default as betting_45shops44LUnItxwzMeta } from "/app/pages/betting-shops.vue?macro=true";
import { default as challenge_45abouth1ddzl5CMPMeta } from "/app/pages/challenge-about.vue?macro=true";
import { default as challenge_45landingFCVn29zyjWMeta } from "/app/pages/challenge-landing.vue?macro=true";
import { default as channels_45subscribeiO5e1doCmXMeta } from "/app/pages/channels-subscribe.vue?macro=true";
import { default as follow_45recommendations8PGXcCanf5Meta } from "/app/pages/follow-recommendations.vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as _91token_93AswCoOqBkbMeta } from "/app/pages/google-login/[token].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as my_45portalxXPjc1CDohMeta } from "/app/pages/my-portal.vue?macro=true";
import { default as new_45postu9AnkQQ7KiMeta } from "/app/pages/new-post.vue?macro=true";
import { default as partners_45contactQITnXevC3oMeta } from "/app/pages/partners-contact.vue?macro=true";
import { default as _91id_93KNKXSehtwXMeta } from "/app/pages/post/[id].vue?macro=true";
import { default as indexS06W1sEZQaMeta } from "/app/pages/professors-challenge/index.vue?macro=true";
import { default as placements5g2nkiNtdjMeta } from "/app/pages/professors-challenge/placements.vue?macro=true";
import { default as predictionsu3Hk34KiMwMeta } from "/app/pages/professors-challenge/predictions.vue?macro=true";
import { default as rulesX0SOcCHoC2Meta } from "/app/pages/professors-challenge/rules.vue?macro=true";
import { default as competition_45archivecGwekpN6voMeta } from "/app/pages/professors/competition-archive.vue?macro=true";
import { default as general_45ruleslUfpIgWeQxMeta } from "/app/pages/professors/general-rules.vue?macro=true";
import { default as index2OXeBtrXwUMeta } from "/app/pages/professors/index.vue?macro=true";
import { default as index1uBqUnZjNiMeta } from "/app/pages/professors/short-challenge/[id]/index.vue?macro=true";
import { default as prizes_45and_45rules2fMokdbJHgMeta } from "/app/pages/professors/short-challenge/[id]/prizes-and-rules.vue?macro=true";
import { default as winnersQnRKOlmjQ8Meta } from "/app/pages/professors/winners.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as _91type_9367ypvVi8XZMeta } from "/app/pages/user-posts/[id]/[type].vue?macro=true";
import { default as _91token_933SsXQZ2GXfMeta } from "/app/pages/user-verify/[token].vue?macro=true";
import { default as _91relation_93PYSh0QJaeVMeta } from "/app/pages/user/[relation].vue?macro=true";
import { default as users_45searchQoHIOliKuzMeta } from "/app/pages/users-search.vue?macro=true";
export default [
  {
    name: activitieswHDF3xSI2CMeta?.name ?? "activities___sr",
    path: activitieswHDF3xSI2CMeta?.path ?? "/activities",
    meta: activitieswHDF3xSI2CMeta || {},
    alias: activitieswHDF3xSI2CMeta?.alias || [],
    redirect: activitieswHDF3xSI2CMeta?.redirect,
    component: () => import("/app/pages/activities.vue").then(m => m.default || m)
  },
  {
    name: activitieswHDF3xSI2CMeta?.name ?? "activities___en",
    path: activitieswHDF3xSI2CMeta?.path ?? "/en/activities",
    meta: activitieswHDF3xSI2CMeta || {},
    alias: activitieswHDF3xSI2CMeta?.alias || [],
    redirect: activitieswHDF3xSI2CMeta?.redirect,
    component: () => import("/app/pages/activities.vue").then(m => m.default || m)
  },
  {
    name: _91id_935onrHzdxxbMeta?.name ?? "betting-shop-id___sr",
    path: _91id_935onrHzdxxbMeta?.path ?? "/betting-shop/:id()",
    meta: _91id_935onrHzdxxbMeta || {},
    alias: _91id_935onrHzdxxbMeta?.alias || [],
    redirect: _91id_935onrHzdxxbMeta?.redirect,
    component: () => import("/app/pages/betting-shop/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_935onrHzdxxbMeta?.name ?? "betting-shop-id___en",
    path: _91id_935onrHzdxxbMeta?.path ?? "/en/betting-shop/:id()",
    meta: _91id_935onrHzdxxbMeta || {},
    alias: _91id_935onrHzdxxbMeta?.alias || [],
    redirect: _91id_935onrHzdxxbMeta?.redirect,
    component: () => import("/app/pages/betting-shop/[id].vue").then(m => m.default || m)
  },
  {
    name: betting_45shops44LUnItxwzMeta?.name ?? "betting-shops___sr",
    path: betting_45shops44LUnItxwzMeta?.path ?? "/betting-shops",
    meta: betting_45shops44LUnItxwzMeta || {},
    alias: betting_45shops44LUnItxwzMeta?.alias || [],
    redirect: betting_45shops44LUnItxwzMeta?.redirect,
    component: () => import("/app/pages/betting-shops.vue").then(m => m.default || m)
  },
  {
    name: betting_45shops44LUnItxwzMeta?.name ?? "betting-shops___en",
    path: betting_45shops44LUnItxwzMeta?.path ?? "/en/betting-shops",
    meta: betting_45shops44LUnItxwzMeta || {},
    alias: betting_45shops44LUnItxwzMeta?.alias || [],
    redirect: betting_45shops44LUnItxwzMeta?.redirect,
    component: () => import("/app/pages/betting-shops.vue").then(m => m.default || m)
  },
  {
    name: challenge_45abouth1ddzl5CMPMeta?.name ?? "challenge-about___sr",
    path: challenge_45abouth1ddzl5CMPMeta?.path ?? "/challenge-about",
    meta: challenge_45abouth1ddzl5CMPMeta || {},
    alias: challenge_45abouth1ddzl5CMPMeta?.alias || [],
    redirect: challenge_45abouth1ddzl5CMPMeta?.redirect,
    component: () => import("/app/pages/challenge-about.vue").then(m => m.default || m)
  },
  {
    name: challenge_45abouth1ddzl5CMPMeta?.name ?? "challenge-about___en",
    path: challenge_45abouth1ddzl5CMPMeta?.path ?? "/en/challenge-about",
    meta: challenge_45abouth1ddzl5CMPMeta || {},
    alias: challenge_45abouth1ddzl5CMPMeta?.alias || [],
    redirect: challenge_45abouth1ddzl5CMPMeta?.redirect,
    component: () => import("/app/pages/challenge-about.vue").then(m => m.default || m)
  },
  {
    name: challenge_45landingFCVn29zyjWMeta?.name ?? "challenge-landing___sr",
    path: challenge_45landingFCVn29zyjWMeta?.path ?? "/challenge-landing",
    meta: challenge_45landingFCVn29zyjWMeta || {},
    alias: challenge_45landingFCVn29zyjWMeta?.alias || [],
    redirect: challenge_45landingFCVn29zyjWMeta?.redirect,
    component: () => import("/app/pages/challenge-landing.vue").then(m => m.default || m)
  },
  {
    name: challenge_45landingFCVn29zyjWMeta?.name ?? "challenge-landing___en",
    path: challenge_45landingFCVn29zyjWMeta?.path ?? "/en/challenge-landing",
    meta: challenge_45landingFCVn29zyjWMeta || {},
    alias: challenge_45landingFCVn29zyjWMeta?.alias || [],
    redirect: challenge_45landingFCVn29zyjWMeta?.redirect,
    component: () => import("/app/pages/challenge-landing.vue").then(m => m.default || m)
  },
  {
    name: channels_45subscribeiO5e1doCmXMeta?.name ?? "channels-subscribe___sr",
    path: channels_45subscribeiO5e1doCmXMeta?.path ?? "/channels-subscribe",
    meta: channels_45subscribeiO5e1doCmXMeta || {},
    alias: channels_45subscribeiO5e1doCmXMeta?.alias || [],
    redirect: channels_45subscribeiO5e1doCmXMeta?.redirect,
    component: () => import("/app/pages/channels-subscribe.vue").then(m => m.default || m)
  },
  {
    name: channels_45subscribeiO5e1doCmXMeta?.name ?? "channels-subscribe___en",
    path: channels_45subscribeiO5e1doCmXMeta?.path ?? "/en/channels-subscribe",
    meta: channels_45subscribeiO5e1doCmXMeta || {},
    alias: channels_45subscribeiO5e1doCmXMeta?.alias || [],
    redirect: channels_45subscribeiO5e1doCmXMeta?.redirect,
    component: () => import("/app/pages/channels-subscribe.vue").then(m => m.default || m)
  },
  {
    name: follow_45recommendations8PGXcCanf5Meta?.name ?? "follow-recommendations___sr",
    path: follow_45recommendations8PGXcCanf5Meta?.path ?? "/follow-recommendations",
    meta: follow_45recommendations8PGXcCanf5Meta || {},
    alias: follow_45recommendations8PGXcCanf5Meta?.alias || [],
    redirect: follow_45recommendations8PGXcCanf5Meta?.redirect,
    component: () => import("/app/pages/follow-recommendations.vue").then(m => m.default || m)
  },
  {
    name: follow_45recommendations8PGXcCanf5Meta?.name ?? "follow-recommendations___en",
    path: follow_45recommendations8PGXcCanf5Meta?.path ?? "/en/follow-recommendations",
    meta: follow_45recommendations8PGXcCanf5Meta || {},
    alias: follow_45recommendations8PGXcCanf5Meta?.alias || [],
    redirect: follow_45recommendations8PGXcCanf5Meta?.redirect,
    component: () => import("/app/pages/follow-recommendations.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1UbT4qDpFeMeta?.name ?? "forgot-password___sr",
    path: forgot_45password1UbT4qDpFeMeta?.path ?? "/forgot-password",
    meta: forgot_45password1UbT4qDpFeMeta || {},
    alias: forgot_45password1UbT4qDpFeMeta?.alias || [],
    redirect: forgot_45password1UbT4qDpFeMeta?.redirect,
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1UbT4qDpFeMeta?.name ?? "forgot-password___en",
    path: forgot_45password1UbT4qDpFeMeta?.path ?? "/en/forgot-password",
    meta: forgot_45password1UbT4qDpFeMeta || {},
    alias: forgot_45password1UbT4qDpFeMeta?.alias || [],
    redirect: forgot_45password1UbT4qDpFeMeta?.redirect,
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: _91token_93AswCoOqBkbMeta?.name ?? "google-login-token___sr",
    path: _91token_93AswCoOqBkbMeta?.path ?? "/google-login/:token()",
    meta: _91token_93AswCoOqBkbMeta || {},
    alias: _91token_93AswCoOqBkbMeta?.alias || [],
    redirect: _91token_93AswCoOqBkbMeta?.redirect,
    component: () => import("/app/pages/google-login/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93AswCoOqBkbMeta?.name ?? "google-login-token___en",
    path: _91token_93AswCoOqBkbMeta?.path ?? "/en/google-login/:token()",
    meta: _91token_93AswCoOqBkbMeta || {},
    alias: _91token_93AswCoOqBkbMeta?.alias || [],
    redirect: _91token_93AswCoOqBkbMeta?.redirect,
    component: () => import("/app/pages/google-login/[token].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___sr",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___sr",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___en",
    path: loginhHM0vSTW5jMeta?.path ?? "/en/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45portalxXPjc1CDohMeta?.name ?? "my-portal___sr",
    path: my_45portalxXPjc1CDohMeta?.path ?? "/my-portal",
    meta: my_45portalxXPjc1CDohMeta || {},
    alias: my_45portalxXPjc1CDohMeta?.alias || [],
    redirect: my_45portalxXPjc1CDohMeta?.redirect,
    component: () => import("/app/pages/my-portal.vue").then(m => m.default || m)
  },
  {
    name: my_45portalxXPjc1CDohMeta?.name ?? "my-portal___en",
    path: my_45portalxXPjc1CDohMeta?.path ?? "/en/my-portal",
    meta: my_45portalxXPjc1CDohMeta || {},
    alias: my_45portalxXPjc1CDohMeta?.alias || [],
    redirect: my_45portalxXPjc1CDohMeta?.redirect,
    component: () => import("/app/pages/my-portal.vue").then(m => m.default || m)
  },
  {
    name: new_45postu9AnkQQ7KiMeta?.name ?? "new-post___sr",
    path: new_45postu9AnkQQ7KiMeta?.path ?? "/new-post",
    meta: new_45postu9AnkQQ7KiMeta || {},
    alias: new_45postu9AnkQQ7KiMeta?.alias || [],
    redirect: new_45postu9AnkQQ7KiMeta?.redirect,
    component: () => import("/app/pages/new-post.vue").then(m => m.default || m)
  },
  {
    name: new_45postu9AnkQQ7KiMeta?.name ?? "new-post___en",
    path: new_45postu9AnkQQ7KiMeta?.path ?? "/en/new-post",
    meta: new_45postu9AnkQQ7KiMeta || {},
    alias: new_45postu9AnkQQ7KiMeta?.alias || [],
    redirect: new_45postu9AnkQQ7KiMeta?.redirect,
    component: () => import("/app/pages/new-post.vue").then(m => m.default || m)
  },
  {
    name: partners_45contactQITnXevC3oMeta?.name ?? "partners-contact___sr",
    path: partners_45contactQITnXevC3oMeta?.path ?? "/partners-contact",
    meta: partners_45contactQITnXevC3oMeta || {},
    alias: partners_45contactQITnXevC3oMeta?.alias || [],
    redirect: partners_45contactQITnXevC3oMeta?.redirect,
    component: () => import("/app/pages/partners-contact.vue").then(m => m.default || m)
  },
  {
    name: partners_45contactQITnXevC3oMeta?.name ?? "partners-contact___en",
    path: partners_45contactQITnXevC3oMeta?.path ?? "/en/partners-contact",
    meta: partners_45contactQITnXevC3oMeta || {},
    alias: partners_45contactQITnXevC3oMeta?.alias || [],
    redirect: partners_45contactQITnXevC3oMeta?.redirect,
    component: () => import("/app/pages/partners-contact.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KNKXSehtwXMeta?.name ?? "post-id___sr",
    path: _91id_93KNKXSehtwXMeta?.path ?? "/post/:id()",
    meta: _91id_93KNKXSehtwXMeta || {},
    alias: _91id_93KNKXSehtwXMeta?.alias || [],
    redirect: _91id_93KNKXSehtwXMeta?.redirect,
    component: () => import("/app/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93KNKXSehtwXMeta?.name ?? "post-id___en",
    path: _91id_93KNKXSehtwXMeta?.path ?? "/en/post/:id()",
    meta: _91id_93KNKXSehtwXMeta || {},
    alias: _91id_93KNKXSehtwXMeta?.alias || [],
    redirect: _91id_93KNKXSehtwXMeta?.redirect,
    component: () => import("/app/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: indexS06W1sEZQaMeta?.name ?? "professors-challenge___sr",
    path: indexS06W1sEZQaMeta?.path ?? "/professors-challenge",
    meta: indexS06W1sEZQaMeta || {},
    alias: indexS06W1sEZQaMeta?.alias || [],
    redirect: indexS06W1sEZQaMeta?.redirect,
    component: () => import("/app/pages/professors-challenge/index.vue").then(m => m.default || m)
  },
  {
    name: indexS06W1sEZQaMeta?.name ?? "professors-challenge___en",
    path: indexS06W1sEZQaMeta?.path ?? "/en/professors-challenge",
    meta: indexS06W1sEZQaMeta || {},
    alias: indexS06W1sEZQaMeta?.alias || [],
    redirect: indexS06W1sEZQaMeta?.redirect,
    component: () => import("/app/pages/professors-challenge/index.vue").then(m => m.default || m)
  },
  {
    name: placements5g2nkiNtdjMeta?.name ?? "professors-challenge-placements___sr",
    path: placements5g2nkiNtdjMeta?.path ?? "/professors-challenge/placements",
    meta: placements5g2nkiNtdjMeta || {},
    alias: placements5g2nkiNtdjMeta?.alias || [],
    redirect: placements5g2nkiNtdjMeta?.redirect,
    component: () => import("/app/pages/professors-challenge/placements.vue").then(m => m.default || m)
  },
  {
    name: placements5g2nkiNtdjMeta?.name ?? "professors-challenge-placements___en",
    path: placements5g2nkiNtdjMeta?.path ?? "/en/professors-challenge/placements",
    meta: placements5g2nkiNtdjMeta || {},
    alias: placements5g2nkiNtdjMeta?.alias || [],
    redirect: placements5g2nkiNtdjMeta?.redirect,
    component: () => import("/app/pages/professors-challenge/placements.vue").then(m => m.default || m)
  },
  {
    name: predictionsu3Hk34KiMwMeta?.name ?? "professors-challenge-predictions___sr",
    path: predictionsu3Hk34KiMwMeta?.path ?? "/professors-challenge/predictions",
    meta: predictionsu3Hk34KiMwMeta || {},
    alias: predictionsu3Hk34KiMwMeta?.alias || [],
    redirect: predictionsu3Hk34KiMwMeta?.redirect,
    component: () => import("/app/pages/professors-challenge/predictions.vue").then(m => m.default || m)
  },
  {
    name: predictionsu3Hk34KiMwMeta?.name ?? "professors-challenge-predictions___en",
    path: predictionsu3Hk34KiMwMeta?.path ?? "/en/professors-challenge/predictions",
    meta: predictionsu3Hk34KiMwMeta || {},
    alias: predictionsu3Hk34KiMwMeta?.alias || [],
    redirect: predictionsu3Hk34KiMwMeta?.redirect,
    component: () => import("/app/pages/professors-challenge/predictions.vue").then(m => m.default || m)
  },
  {
    name: rulesX0SOcCHoC2Meta?.name ?? "professors-challenge-rules___sr",
    path: rulesX0SOcCHoC2Meta?.path ?? "/professors-challenge/rules",
    meta: rulesX0SOcCHoC2Meta || {},
    alias: rulesX0SOcCHoC2Meta?.alias || [],
    redirect: rulesX0SOcCHoC2Meta?.redirect,
    component: () => import("/app/pages/professors-challenge/rules.vue").then(m => m.default || m)
  },
  {
    name: rulesX0SOcCHoC2Meta?.name ?? "professors-challenge-rules___en",
    path: rulesX0SOcCHoC2Meta?.path ?? "/en/professors-challenge/rules",
    meta: rulesX0SOcCHoC2Meta || {},
    alias: rulesX0SOcCHoC2Meta?.alias || [],
    redirect: rulesX0SOcCHoC2Meta?.redirect,
    component: () => import("/app/pages/professors-challenge/rules.vue").then(m => m.default || m)
  },
  {
    name: competition_45archivecGwekpN6voMeta?.name ?? "professors-competition-archive___sr",
    path: competition_45archivecGwekpN6voMeta?.path ?? "/professors/competition-archive",
    meta: competition_45archivecGwekpN6voMeta || {},
    alias: competition_45archivecGwekpN6voMeta?.alias || [],
    redirect: competition_45archivecGwekpN6voMeta?.redirect,
    component: () => import("/app/pages/professors/competition-archive.vue").then(m => m.default || m)
  },
  {
    name: competition_45archivecGwekpN6voMeta?.name ?? "professors-competition-archive___en",
    path: competition_45archivecGwekpN6voMeta?.path ?? "/en/professors/competition-archive",
    meta: competition_45archivecGwekpN6voMeta || {},
    alias: competition_45archivecGwekpN6voMeta?.alias || [],
    redirect: competition_45archivecGwekpN6voMeta?.redirect,
    component: () => import("/app/pages/professors/competition-archive.vue").then(m => m.default || m)
  },
  {
    name: general_45ruleslUfpIgWeQxMeta?.name ?? "professors-general-rules___sr",
    path: general_45ruleslUfpIgWeQxMeta?.path ?? "/professors/general-rules",
    meta: general_45ruleslUfpIgWeQxMeta || {},
    alias: general_45ruleslUfpIgWeQxMeta?.alias || [],
    redirect: general_45ruleslUfpIgWeQxMeta?.redirect,
    component: () => import("/app/pages/professors/general-rules.vue").then(m => m.default || m)
  },
  {
    name: general_45ruleslUfpIgWeQxMeta?.name ?? "professors-general-rules___en",
    path: general_45ruleslUfpIgWeQxMeta?.path ?? "/en/professors/general-rules",
    meta: general_45ruleslUfpIgWeQxMeta || {},
    alias: general_45ruleslUfpIgWeQxMeta?.alias || [],
    redirect: general_45ruleslUfpIgWeQxMeta?.redirect,
    component: () => import("/app/pages/professors/general-rules.vue").then(m => m.default || m)
  },
  {
    name: index2OXeBtrXwUMeta?.name ?? "professors___sr",
    path: index2OXeBtrXwUMeta?.path ?? "/professors",
    meta: index2OXeBtrXwUMeta || {},
    alias: index2OXeBtrXwUMeta?.alias || [],
    redirect: index2OXeBtrXwUMeta?.redirect,
    component: () => import("/app/pages/professors/index.vue").then(m => m.default || m)
  },
  {
    name: index2OXeBtrXwUMeta?.name ?? "professors___en",
    path: index2OXeBtrXwUMeta?.path ?? "/en/professors",
    meta: index2OXeBtrXwUMeta || {},
    alias: index2OXeBtrXwUMeta?.alias || [],
    redirect: index2OXeBtrXwUMeta?.redirect,
    component: () => import("/app/pages/professors/index.vue").then(m => m.default || m)
  },
  {
    name: index1uBqUnZjNiMeta?.name ?? "professors-short-challenge-id___sr",
    path: index1uBqUnZjNiMeta?.path ?? "/professors/short-challenge/:id()",
    meta: index1uBqUnZjNiMeta || {},
    alias: index1uBqUnZjNiMeta?.alias || [],
    redirect: index1uBqUnZjNiMeta?.redirect,
    component: () => import("/app/pages/professors/short-challenge/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1uBqUnZjNiMeta?.name ?? "professors-short-challenge-id___en",
    path: index1uBqUnZjNiMeta?.path ?? "/en/professors/short-challenge/:id()",
    meta: index1uBqUnZjNiMeta || {},
    alias: index1uBqUnZjNiMeta?.alias || [],
    redirect: index1uBqUnZjNiMeta?.redirect,
    component: () => import("/app/pages/professors/short-challenge/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: prizes_45and_45rules2fMokdbJHgMeta?.name ?? "professors-short-challenge-id-prizes-and-rules___sr",
    path: prizes_45and_45rules2fMokdbJHgMeta?.path ?? "/professors/short-challenge/:id()/prizes-and-rules",
    meta: prizes_45and_45rules2fMokdbJHgMeta || {},
    alias: prizes_45and_45rules2fMokdbJHgMeta?.alias || [],
    redirect: prizes_45and_45rules2fMokdbJHgMeta?.redirect,
    component: () => import("/app/pages/professors/short-challenge/[id]/prizes-and-rules.vue").then(m => m.default || m)
  },
  {
    name: prizes_45and_45rules2fMokdbJHgMeta?.name ?? "professors-short-challenge-id-prizes-and-rules___en",
    path: prizes_45and_45rules2fMokdbJHgMeta?.path ?? "/en/professors/short-challenge/:id()/prizes-and-rules",
    meta: prizes_45and_45rules2fMokdbJHgMeta || {},
    alias: prizes_45and_45rules2fMokdbJHgMeta?.alias || [],
    redirect: prizes_45and_45rules2fMokdbJHgMeta?.redirect,
    component: () => import("/app/pages/professors/short-challenge/[id]/prizes-and-rules.vue").then(m => m.default || m)
  },
  {
    name: winnersQnRKOlmjQ8Meta?.name ?? "professors-winners___sr",
    path: winnersQnRKOlmjQ8Meta?.path ?? "/professors/winners",
    meta: winnersQnRKOlmjQ8Meta || {},
    alias: winnersQnRKOlmjQ8Meta?.alias || [],
    redirect: winnersQnRKOlmjQ8Meta?.redirect,
    component: () => import("/app/pages/professors/winners.vue").then(m => m.default || m)
  },
  {
    name: winnersQnRKOlmjQ8Meta?.name ?? "professors-winners___en",
    path: winnersQnRKOlmjQ8Meta?.path ?? "/en/professors/winners",
    meta: winnersQnRKOlmjQ8Meta || {},
    alias: winnersQnRKOlmjQ8Meta?.alias || [],
    redirect: winnersQnRKOlmjQ8Meta?.redirect,
    component: () => import("/app/pages/professors/winners.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register___sr",
    path: registermu8R7rSP5uMeta?.path ?? "/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register___en",
    path: registermu8R7rSP5uMeta?.path ?? "/en/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name ?? "settings___sr",
    path: settingsLwEYOlkQxrMeta?.path ?? "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    alias: settingsLwEYOlkQxrMeta?.alias || [],
    redirect: settingsLwEYOlkQxrMeta?.redirect,
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name ?? "settings___en",
    path: settingsLwEYOlkQxrMeta?.path ?? "/en/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    alias: settingsLwEYOlkQxrMeta?.alias || [],
    redirect: settingsLwEYOlkQxrMeta?.redirect,
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: _91type_9367ypvVi8XZMeta?.name ?? "user-posts-id-type___sr",
    path: _91type_9367ypvVi8XZMeta?.path ?? "/user-posts/:id()/:type()",
    meta: _91type_9367ypvVi8XZMeta || {},
    alias: _91type_9367ypvVi8XZMeta?.alias || [],
    redirect: _91type_9367ypvVi8XZMeta?.redirect,
    component: () => import("/app/pages/user-posts/[id]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91type_9367ypvVi8XZMeta?.name ?? "user-posts-id-type___en",
    path: _91type_9367ypvVi8XZMeta?.path ?? "/en/user-posts/:id()/:type()",
    meta: _91type_9367ypvVi8XZMeta || {},
    alias: _91type_9367ypvVi8XZMeta?.alias || [],
    redirect: _91type_9367ypvVi8XZMeta?.redirect,
    component: () => import("/app/pages/user-posts/[id]/[type].vue").then(m => m.default || m)
  },
  {
    name: _91token_933SsXQZ2GXfMeta?.name ?? "user-verify-token___sr",
    path: _91token_933SsXQZ2GXfMeta?.path ?? "/user-verify/:token()",
    meta: _91token_933SsXQZ2GXfMeta || {},
    alias: _91token_933SsXQZ2GXfMeta?.alias || [],
    redirect: _91token_933SsXQZ2GXfMeta?.redirect,
    component: () => import("/app/pages/user-verify/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_933SsXQZ2GXfMeta?.name ?? "user-verify-token___en",
    path: _91token_933SsXQZ2GXfMeta?.path ?? "/en/user-verify/:token()",
    meta: _91token_933SsXQZ2GXfMeta || {},
    alias: _91token_933SsXQZ2GXfMeta?.alias || [],
    redirect: _91token_933SsXQZ2GXfMeta?.redirect,
    component: () => import("/app/pages/user-verify/[token].vue").then(m => m.default || m)
  },
  {
    name: _91relation_93PYSh0QJaeVMeta?.name ?? "user-relation___sr",
    path: _91relation_93PYSh0QJaeVMeta?.path ?? "/user/:relation()",
    meta: _91relation_93PYSh0QJaeVMeta || {},
    alias: _91relation_93PYSh0QJaeVMeta?.alias || [],
    redirect: _91relation_93PYSh0QJaeVMeta?.redirect,
    component: () => import("/app/pages/user/[relation].vue").then(m => m.default || m)
  },
  {
    name: _91relation_93PYSh0QJaeVMeta?.name ?? "user-relation___en",
    path: _91relation_93PYSh0QJaeVMeta?.path ?? "/en/user/:relation()",
    meta: _91relation_93PYSh0QJaeVMeta || {},
    alias: _91relation_93PYSh0QJaeVMeta?.alias || [],
    redirect: _91relation_93PYSh0QJaeVMeta?.redirect,
    component: () => import("/app/pages/user/[relation].vue").then(m => m.default || m)
  },
  {
    name: users_45searchQoHIOliKuzMeta?.name ?? "users-search___sr",
    path: users_45searchQoHIOliKuzMeta?.path ?? "/users-search",
    meta: users_45searchQoHIOliKuzMeta || {},
    alias: users_45searchQoHIOliKuzMeta?.alias || [],
    redirect: users_45searchQoHIOliKuzMeta?.redirect,
    component: () => import("/app/pages/users-search.vue").then(m => m.default || m)
  },
  {
    name: users_45searchQoHIOliKuzMeta?.name ?? "users-search___en",
    path: users_45searchQoHIOliKuzMeta?.path ?? "/en/users-search",
    meta: users_45searchQoHIOliKuzMeta || {},
    alias: users_45searchQoHIOliKuzMeta?.alias || [],
    redirect: users_45searchQoHIOliKuzMeta?.redirect,
    component: () => import("/app/pages/users-search.vue").then(m => m.default || m)
  }
]